<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ rptDefaultName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">
      <span class="fw-semi-bold">{{ rptDefaultName }}</span>
      <span class="fw-normal fs-larger"> {{ rptVersion }} </span>
    </h2>
    <b-row>
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <b-row class="form">
            <!-- Filter Period -->
            <b-col xs="12" md="3" class="form-col-last">              
              <b-form-group
                label="Period"
                label-for="Period"
                label-cols-sm="4"
                label-align-sm="left"
                label-size="sm"
              >
                <VueDatePicker
                  class="vd__div"
                  v-model="selectedPeriod"
                  no-header
                  type="month"
                  format="MMM YYYY"
                  :min-date="minMonthDate"
                  :max-date="maxMonthDate"
                  placeholder="Choose Period"
                  />
                </b-form-group>
            </b-col>
            <!-- end Period-->
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button
                  class="btn-spacing"
                  type="button"
                  variant="primary"
                  :disabled="isLoadingData"
                  v-on:click="populateReport()"
                  >Load Report</b-button
                >
                <download-excel
                  :class="exportButtonClass()"
                  :data="resultReport2"
                  :fields="excel_fields"
                  :title="excel_title"
                  :name="excel_name"
                >
                  Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button
                  class="btn-spacing-left"
                  type="button"
                  variant="secondary"
                  v-on:click="goToReport()"
                  >Back</b-button
                >
              </b-col>
            </b-row>
          </div>
          <br />
          <div>
            <b-row>
              <b-col sm="12">
                <b-row>
                  <b-col xs="5" md="4" lg="2">Processed at</b-col>
                  <b-col xs="7" md="8" lg="10">: {{ processedDateTime }}</b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br />
    <hr />
    <b-row>
      <b-col xs="12">
        <div>
          <b-table
            hover
            responsive
            id="my-table"
            :items="resultReport2"
            :fields="fields"
            :busy="isBusy"
            head-variant="dark"
            sticky-header="550px"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <template #cell(td_group_pnl)="data">
              <div :class="getCustomClass(data.item.group_pnl, data.item.description)"> {{ data.item.description? data.item.description : data.item.group_pnl }} </div>
            </template>
            <template #cell(td_description)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ data.item.description }} </div>
            </template>
            <template #cell(td_total)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.total)}} </div>
            </template>
            <template #cell(td_uo)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.uo)}} </div>
            </template>
            <template #cell(td_wholesale)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.wholesale)}} </div>
            </template>
            <template #cell(td_others)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.others)}} </div>
            </template>
            <template #cell(td_retail)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.retail)}} </div>
            </template>
            <template #cell(td_rtl_deptstore)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtl_deptstore)}} </div>
            </template>
            <template #cell(td_rtlstore_matahari)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtlstore_matahari)}} </div>
            </template>
            <template #cell(td_rtlstore_metro)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtlstore_metro)}} </div>
            </template>
            <template #cell(td_rtlstore_sogo)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtlstore_sogo)}} </div>
            </template>
            <template #cell(td_rtlstore_star)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtlstore_star)}} </div>
            </template>
            <template #cell(td_rtlstore_centro)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtlstore_centro)}} </div>
            </template>
            <template #cell(td_rtlstore_others)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtlstore_others)}} </div>
            </template>
            <template #cell(td_rtl_showroom)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtlstore_showroom)}} </div>
            </template>
            <template #cell(td_rtl_bazar)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtl_bazar)}} </div>
            </template>
            <template #cell(td_rtl_online)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtl_online)}} </div>
            </template>
            <template #cell(td_rtl_others)="data">
              <div :class="getCustomClass(data.item.group_pnl)"> {{ formatThousandSeparator(data.item.rtl_others)}} </div>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Import api-method fbPOST
import { fnGET, fnPOST } from "@/api/api-methods";
// Import api-path HR Daily Health V2 dan Site List
import { APIPATH_PNL } from "@/api/api-paths";
import { mapState, mapActions, mapGetters } from "vuex";
import { fnApiFetchListDepartment } from "@/api/api-reports.js";

import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { BootstrapVue, IconsPlugin, BPagination } from "bootstrap-vue";
import { showToastOK } from "@/core/pluginHelper";
import loginStore from "@/store/login.js";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("b-pagination", BPagination);
Vue.component("download-excel", JsonExcel);

export default {
  name: "PNLSummaryReport",
  data() {
    return {
      frmCode: "RPTFIN002",
      rptDefaultName: "P/L Detail",
      rptVersion: "v1",
      errorMessage: null,
      selectedPeriod: null,
      minMonthDate: '2021-01-01',
      maxMonthDate: null,
      resultReport2: [],
      fields: [
        { key: "td_group_pnl", label: "CHANNEL", thClass: 'text-center' },
        // { key: "td_descriptionl", label: "DESCRIPTION", thClass: 'text-center' },
        { key: "td_total", label: "GRAND TOTAL", tdClass: 'text-right', thClass: 'text-center' },
        { key: "td_uo", label: "UNIFORM", tdClass: 'text-right', thClass: 'text-center' },
        { key: "td_wholesale", label: "WHOLESALE", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_others", label: "OTHERS", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_retail", label: "RETAIL", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtl_deptstore", label: "DEPTSTORE", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtlstore_matahari", label: "MATAHARI", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtlstore_metro", label: "METRO", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtlstore_sogo", label: "SOGO", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtlstore_star", label: "STAR", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtlstore_centro", label: "CENTRO", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtlstore_others", label: "OTHERS (DEPTSTORE)", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtl_showroom", label: "SHOWROOM", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtl_bazar", label: "BAZAR", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtl_online", label: "ONLINE", tdClass: 'text-right', thClass: 'text-center'},
        { key: "td_rtl_others", label: "OTHERS (RETAIL)", tdClass: 'text-right', thClass: 'text-center'},
      ],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      excel_fields: {
        "CHANNEL": "group_pnl",
        "DESCRIPTION": "description",
        "GRAND TOTAL": "total",
        "UNIFORM": "uo",
        "WHOLESALE": "wholesale",
        "OTHERS": "others",
        "RETAIL": "retail",
        "DEPTSTORE": "rtl_deptstore",
        "MATAHARI": "rtlstore_matahari",
        "METRO": "rtlstore_metro",
        "SOGO": "rtlstore_sogo",
        "STAR": "rtlstore_star",
        "CENTRO": "rtlstore_centro",
        "OTHERS (DEPTSTORE)": "rtlstore_others",
        "SHOWROOM": "rtl_showroom",
        "BAZAR": "rtl_bazar",
        "ONLINE": "rtl_online",
        "OTHERS (RETAIL)": "rtl_others",
      },
      processedDateTime: "",
      excel_title: "",
      excel_name: "",
      isLoadingData: false,
    };
  },
  computed: {
    ...mapState("report", ["stActiveReport", "stDeptList"]),
    ...mapGetters("session", ["userData", "localDate"]),
    rows() {
      return this.resultReport2.length;
    },
    computePercentage(val1, val2){
      let result = null
      let pct  = parseInt(100*val1/val2)
      result = pct.toString()

      return result
    }
  },
  methods: {
    ...mapActions("report", [
      "getCurrentDatetime",
      "generateTitleXLS",
      "initializeReport",
    ]),

    getCustomClass (group_pnl, description) {
      // let listBoldClass = ['GROSS SALES', 'NET CASHIER', 'DPP', 'PPN', 'NETT', 'GROSS PROFIT', 'EXPENSES STORE', 'NPAM (After Store Direct Exp.)', 'EXPENSES OFFICE', 'NPAM', 'EXPENSES GA', 'NET OPERATING INCOME', 'EXPENSES FINANCIAL', 'NPBT']
      let listBoldClass = ['DPP', 'GROSS PROFIT', 'NPAM (After Store Direct Exp.)', 'NPAM', 'NET OPERATING INCOME', 'NPBT']
      // switch (group_pnl) {
      //   case 'GROSS SALES':
      //     return 'font-weight-bold'
      //   case 'NET CASHIER':
      //     return 'font-weight-bold font-italic'
      //   default:
      //     return null
      if(description) {
        return 'text-right'
      }
      if (listBoldClass.includes(group_pnl)){
        return 'font-weight-bold' 
      }

      return 'ml-2' 
    },
    formatThousandSeparator(number) {
      if(!number) {
        number = '-'
      }
      let num_parts = number.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    },
    exportButtonClass() {
      let classNames = "btn btn-info";
      if (this.resultReport2.length == 0) {
        classNames += " hide";
      }
      if (this.isLoadingData) {
        classNames += " btn-disabled";
      }
      return classNames;
    },
    initExcelTitle() {
      this.generateTitleXLS({
        rptName: this.rptDefaultName,
        rptVersion: this.rptVersion,
        processedDateTime: this.processedDateTime,
        processedBy: this.userData.username + " - " + this.userData.fullname,
        additional: "period: " + this.selectedPeriod,
      }).then((rsp) => {
        this.excel_name = rsp[0];
        this.excel_title = rsp[1];
      });
    },
    populateReport() {
      this.resetReportSummary();
      this.isBusy = !this.isBusy;
      this.isLoadingData = true;
      fnPOST(APIPATH_PNL.pnlRptDetail, {
        date:(this.selectedPeriod+'-01')
      }).then((rsp) => {
        this.isBusy = !this.isBusy;
        this.$set(this, "resultReport2", rsp.data.payload);

        console.log(Object.keys(this.resultReport2));

        this.getCurrentDatetime()
          .then((rsp) => {
            this.processedDateTime = rsp;
            showToastOK({ type: "success", message: "Report Loaded" });

            this.initExcelTitle();
          })
          .catch((ex) => {
            showToastOK({ type: "error", message: "Failed loading report" });
          })
          .finally(() => {
            this.isLoadingData = false;
          });
      });
    },

    goToReport() {
      this.$router.push({ name: "ReportPage" });
    },
    resetReportSummary() {
      this.errorMessage = null;
      this.processedDateTime = "";
    },
    onLoadRpt(){
      if(this.stActiveReport) {
        this.rptDefaultName = this.stActiveReport
      }
    },
  },
  created() {
    this.initializeReport();
    this.onLoadRpt()
    let today   = new Date()
    // let today = new Date(2022,0,11)
    this.maxMonthDate   = new Date(today.getFullYear(), today.getMonth() -1, 1)
    let curYear  = this.maxMonthDate.getFullYear()
    let curMonth = (this.maxMonthDate.getMonth() + 1)
    this.selectedPeriod = curYear.toString() + '-' + ("0" + curMonth).slice(-2)
    
    console.log(this.selectedPeriod)
  },
};
</script>

<style src="./PNLReport.scss" lang="scss" scoped />

<link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css"/>
